import React, { useState } from "react";
import Page from "../components/Page";
import { graphql, Link } from "gatsby";

export default function Index({ data }) {
  return (
    <>
      <Page title={"Hitta blombutiker - Blomstertjänsten"}>
        <section className="container max-w-screen-xl px-5 py-6 mx-auto ">
          <br />
          <h1 className="font-bold text-4xl md:text-5xl text-gray-900 leading-tight mb-8">
            Hitta blombutiker i din stad
          </h1>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
            {data.allCitiesJson.edges.map((city, index) => {
              return (
                <Link
                  to={"/butiker" + city.node.url}
                  className="block p-6 bg-white rounded-lg border border-gray-200 shadow-md hover:bg-gray-100 "
                >
                  <h2 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">
                    {city.node.title}
                  </h2>
                  <p className="font-normal text-gray-700">
                    Hitta addresser och kontaktinformation till olika
                    blombutiker som ligger i {city.node.title}.
                  </p>
                  <div
                 
                    class="inline-flex items-center text-blue-600 hover:underline mt-3"
                  >
                    Läs mer
                    <svg
                      class="ml-2 w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z"></path>
                      <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z"></path>
                    </svg>
                  </div>
                </Link>
              );
            })}
          </div>
        </section>
      </Page>
    </>
  );
}

export const pageQuery = graphql`
  query {
    allCitiesJson {
      edges {
        node {
          url
          title
        }
      }
    }
  }
`;
